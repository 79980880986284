import * as React from "react";
import sanityClient from "@sanity/client";
import { globalHistory } from "@reach/router";
import useWindowSize from "utils/useWindowSize";

const defaultValues = {};

export const SiteContext = React.createContext(defaultValues);

export const SiteProvider = ({ children }) => {
  const [ Smoother, setSmoother ] = React.useState(false);
  const [ IntroComplete, setIntroComplete ] = React.useState(false);
  const isFirstAccess = React.useRef(true);
  const [ isCursor, setIsCursor ] = React.useState(false);
  const [ isWatch, setIsWatch ] = React.useState(false);
  const [isSidebar, setSidebar] = React.useState();
  const [ width ] = useWindowSize();

  const client = sanityClient({
    projectId: "ty5k9a1o",
    dataset: "production",
    apiVersion: "2021-10-21", // use current UTC date - see "specifying API version"!
    token: "", // or leave blank for unauthenticated usage
    useCdn: true, // `false` if you want to ensure fresh data
  });

  React.useEffect(() => {
    globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        isFirstAccess.current = false;
      }
    })
  }, []);

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        client,
        isFirstAccess,
        Smoother,
        setSmoother,
        IntroComplete,
        setIntroComplete,
        isCursor,
        setIsCursor,
        setIsWatch,
        isWatch,
        width,
        isSidebar,
        setSidebar
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
